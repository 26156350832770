import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.reducer';

export const selectSessionState = createFeatureSelector<SessionState>('session');

export const selectAllSessions = createSelector(
  selectSessionState,
  (state: SessionState) => state.sessions
);

export const selectAllFinishedSessions = createSelector(
  selectSessionState,
  (state: SessionState) => state.finishedSessions
);

export const selectSessionById = (sessionId: number) => createSelector(
  selectAllSessions,
  (sessions) => sessions.find((session) => session.id === sessionId)
);
