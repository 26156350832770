import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { EmptyComponent } from '../../common-components/empty/empty.component';
import { ConsultationSymptomData } from '../../../../../models/session-model';
import { SymptomItemComponent } from '../symptom-item/symptom-item.component';

@Component({
  selector: 'hippocraticum-ai-frontend-consultation-symptoms',
  standalone: true,
  imports: [CommonModule, MatIcon, EmptyComponent, SymptomItemComponent],
  templateUrl: './consultation-symptoms.component.html',
  styleUrl: './consultation-symptoms.component.scss',
})
export class ConsultationSymptomsComponent {
  @Input() isHalfHeight: boolean = false;
  @Input() symptoms: ConsultationSymptomData[] | undefined = [];
  @Output() close = new EventEmitter<void>();
}
