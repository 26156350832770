import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { Router } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
  selector: 'hippocraticum-ai-frontend-account-menu',
  standalone: true,
  imports: [CommonModule, CdkMenuModule, NgxTolgeeModule],
  templateUrl: './account-menu.component.html',
  styleUrl: './account-menu.component.scss',
})
export class AccountMenuComponent {
  @Output() editPasswordSelected = new EventEmitter();
  @Output() logoutSelected = new EventEmitter();

  constructor() {}

  public logout(): void {
    this.logoutSelected.emit();
  }
}
