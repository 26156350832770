import { Route } from '@angular/router';
import { ConsultationComponent } from '../../../../libs/common/components/src/lib/consultation-components/consultation/consultation.component';
import { AuthGuard } from '../../../../libs/common/guards/auth/auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'sign-in',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        '../../../../libs/common/components/src/lib/authentication-components/sign-in/sign-in.component'
      ).then((m) => m.SignInComponent),
  },
  {
    path: 'sign-up',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        '../../../../libs/common/components/src/lib/authentication-components/sign-up/sign-up.component'
      ).then((m) => m.SignUpComponent),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import(
        '../../../../libs/common/components/src/lib/session-management-components/dashboard/dashboard.component'
      ).then((m) => m.DashboardComponent),
  },
  {
    path: 'consultation-meet-doctor',
    loadComponent: () =>
      import(
        '../../../../libs/features/consultation-meet/src/lib/consultation-meet.component'
      ).then((m) => m.ConsultationMeetComponent),
    data: { identity: 'doctor' },
  },
  {
    path: 'patient-verification-page',
    loadComponent: () =>
      import(
        '../../../../libs/features/consultation-meet/src/lib/pages/patient-verification-page/patient-verification-page.component'
      ).then((m) => m.PatientVerificationPageComponent)
  },
  {
    path: 'patient-waiting-room',
    loadComponent: () =>
      import(
        '../../../../libs/features/consultation-meet/src/lib/pages/patient-waiting-room/patient-waiting-room.component'
      ).then((m) => m.PatientWaitingRoomComponent)
  },
  {
    path: 'consultation-meet-patient',
    loadComponent: () =>
      import(
        '../../../../libs/features/consultation-meet/src/lib/consultation-meet.component'
      ).then((m) => m.ConsultationMeetComponent),
    data: { identity: 'patient' },
  },
  {
    path: 'ui-kit',
    loadComponent: () =>
      import('../../../../libs/ui-kit/src/lib/ui-kit.component').then(
        (m) => m.UiKitComponent
      ),
  },
  {
    path: 'consultation',
    canActivate: [AuthGuard],
    children: [{ path: ':id', component: ConsultationComponent }],
  },
  {
    path: '**',
    redirectTo: 'sign-in',
  },
  // {
  //   path: '',
  //   component: ShellComponent,
  //   children: [
  //     {
  //       path: 'consultation-demo',
  //       loadChildren: () =>
  //         import('@hippocraticum-ai-frontend/consultation-demo').then(
  //           (m) => m.featureRoutes
  //         ),
  //     },
  //     {
  //       path: '**',
  //       redirectTo: 'consultation-demo',
  //     },
  //   ],
  // },
];
