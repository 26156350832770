import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as PatientActions from './patient.actions';
import { SessionManagementService } from '../../../../apps/platform/src/app/services/session-management/session-management.service';

@Injectable()
export class PatientEffects {
  addPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.addPatient),
      mergeMap((action) =>
        this.sessionManagementService.addPatient(action.patient).pipe(
          map((patient) => PatientActions.addPatientSuccess({ patient })),
          catchError((error) => of(PatientActions.addPatientFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sessionManagementService: SessionManagementService
  ) { }
}
