import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LanguageActions from './report-language.actions';
import { LanguagesService } from '../../services/languages/languages.service';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class ReportLanguageEffects {
  addPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadReportLanguages),
      mergeMap(() =>
        this.languagesService.getReportLanguages().pipe(
          map((languages) =>
            LanguageActions.loadReportLanguagesSuccess({ languages })
          ),
          catchError((error) =>
            of(LanguageActions.loadReportLanguagesFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly languagesService: LanguagesService
  ) { }
}
