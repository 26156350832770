import { Injectable } from '@angular/core';
import { SessionModel } from '../../../../../../libs/common/models/session-model';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { PatientModel } from '../../../../../../libs/common/models/patient-model';

@Injectable({
  providedIn: 'root',
})
export class SessionManagementService {
  private sessions: SessionModel[] = [];
  private patients: PatientModel[] = [
    { id: 1, name: 'Jon Snow', patientDOB: moment('1990-01-01'), gender: 'male', email: 'jon.snow@example.com', phone: '+41 79 821 60 85' },
    { id: 2, name: 'Daenerys Targaryen', patientDOB: moment('1990-02-01'), gender: 'female', email: 'daenerys.targaryen@example.com', phone: '+41 79 821 60 86' },
    { id: 3, name: 'Tyrion Lannister', patientDOB: moment('1990-03-01'), gender: 'male', email: 'tyrion.lannister@example.com', phone: '+41 79 821 60 88' },
    { id: 4, name: 'Arya Stark', patientDOB: moment('1990-04-01'), gender: 'female', email: 'arya.stark@example.com', phone: '+41 79 820 60 85' },
    { id: 5, name: 'Sansa Stark', patientDOB: moment('1990-05-01'), gender: 'female', email: 'sansa.stark@example.com', phone: '+41 79 851 60 85' },
    { id: 6, name: 'Bran Stark', patientDOB: moment('1990-06-01'), gender: 'male', email: 'bran.stark@example.com', phone: '+41 79 821 60 77' },
    { id: 7, name: 'Cersei Lannister', patientDOB: moment('1990-07-01'), gender: 'female', email: 'cersei.lannister@example.com', phone: '+41 79 821 60 99' },
    { id: 8, name: 'Jaime Lannister', patientDOB: moment('1990-08-01'), gender: 'male', email: 'jaime.lannister@example.com', phone: '+41 55 821 60 85' },
    { id: 9, name: 'Joffrey Baratheon', patientDOB: moment('1990-09-01'), gender: 'male', email: 'joffrey.baratheon@example.com', phone: '+41 79 777 60 85' },
    { id: 10, name: 'Margaery Tyrell', patientDOB: moment('1990-10-01'), gender: 'female', email: 'margaery.tyrell@example.com', phone: '+41 79 888 60 85' }
  ];
  private finishedSessions: SessionModel[] = [
    {
      id: 1,
      name: 'Jon Snow',
      date: new Date('2023-01-01'),
      isFinished: true,
      patientDOB: moment('1990-01-01'),
      gender: 'Male',
      email: 'jon.snow@example.com',
      phone: '123-456-7890',
    },
    {
      id: 2,
      name: 'Daenerys Targaryen',
      date: new Date('2023-02-01'),
      isFinished: true,
      patientDOB: moment('1990-02-01'),
      gender: 'Female',
      email: 'daenerys.targaryen@example.com',
      phone: '123-456-7891',
    },
    {
      id: 3,
      name: 'Tyrion Lannister',
      date: new Date('2023-03-01'),
      isFinished: true,
      patientDOB: moment('1990-03-01'),
      gender: 'Male',
      email: 'tyrion.lannister@example.com',
      phone: '123-456-7892',
    },
  ];

  constructor() {}

  private generateId(): number {
    const allSessions = [...this.sessions, ...this.finishedSessions];
    return allSessions.length > 0
      ? Math.max(...allSessions.map((session) => session.id ?? 0)) + 1
      : 1;
  }

  private generatePatientId(): number {
    return this.patients.length > 0
      ? Math.max(...this.patients.map((patient) => patient.id ?? 0)) + 1
      : 1;
  }

  createSession(session: SessionModel): Observable<SessionModel> {
    session.id = this.generateId();
    this.sessions = [...this.sessions, session];
    return of(session);
  }

  addPatient(patient: PatientModel): Observable<PatientModel> {
    const newPatient = { ...patient, id: this.generatePatientId() };
    this.patients = [...this.patients, newPatient];
    return of(newPatient);
  }

  updateSession(session: SessionModel): Observable<SessionModel> {
    return of(session);
  }

  removeSession(sessionId: number): Observable<number> {
    this.sessions = this.sessions.filter(session => session.id !== sessionId);
    return of(sessionId);
  }

  getAllSessions(): Observable<SessionModel[]> {
    return of(this.sessions);
  }

  createFinishedSession(session: SessionModel): Observable<SessionModel> {
    this.finishedSessions = [...this.finishedSessions, session];
    return of(session);
  }

  getAllFinishedSessions(): Observable<SessionModel[]> {
    return of(this.finishedSessions);
  }

  searchPatients(query: string): Observable<PatientModel[]> {
    return of(this.patients.filter(patient => patient?.name?.toLowerCase().includes(query?.toLowerCase())));
    //return this.http.get<any[]>(`/api/patients?search=${query}`).pipe(
    //  map((response) => response || [])
    //);
  }
}
