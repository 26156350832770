import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}


  registerIcons(icons: { [key: string]: string }) {
    for (const [iconName, iconPath] of Object.entries(icons)) {
      // Ensure that the iconPath is from a trusted source and cannot be manipulated by users
      if (this.isTrustedSource(iconPath)) {
        this.iconRegistry.addSvgIcon(
          iconName,
          this.sanitizer.bypassSecurityTrustResourceUrl(iconPath)
        );
      } else {
        console.warn(`Icon path ${iconPath} is not from a trusted source.`);
      }
    }
  }

  registerAllIcons(): void {
    this.registerIcons({
      'summary': 'assets/summary.svg',
      'symptoms': 'assets/symptoms.svg',
      'medications': 'assets/medications.svg',
      'logo': 'assets/logo.svg',
      'logoGrey': 'assets/logo-grey.svg',
      'record': 'assets/record-icon.svg',
      'pause': 'assets/pause-icon.svg',
    });
  }

  private isTrustedSource(url: string): boolean {
    return url.startsWith('assets/');
  }
}
