import { createAction, props } from '@ngrx/store';
import { PatientModel } from '../../models/patient-model';

export const addPatient = createAction(
  '[Patient] Add Patient',
  props<{ patient: PatientModel }>()
);

export const addPatientSuccess = createAction(
  '[Patient] Add Patient Success',
  props<{ patient: PatientModel }>()
);

export const addPatientFailure = createAction(
  '[Patient] Add Patient Failure',
  props<{ error: any }>()
);
