import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../common-components/header/header.component';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { ConsultationSessionComponent } from '../consultation-session/consultation-session.component';
import { ConsultationSummaryComponent } from '../consultation-summary/consultation-summary.component';
import { ConsultationSymptomsComponent } from '../consultation-symptoms/consultation-symptoms.component';
import { ConsultationMedicationComponent } from '../consultation-medication/consultation-medication.component';
import { selectSessionById } from '../../../../../store/sessions/session.selectors';
import { SessionModel } from '../../../../../models/session-model';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../../store/sessions/session.reducer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hippocraticum-ai-frontend-consultation',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    MatIcon,
    ConsultationSessionComponent,
    ConsultationSummaryComponent,
    ConsultationSymptomsComponent,
    ConsultationMedicationComponent,
  ],
  templateUrl: './consultation.component.html',
  styleUrl: './consultation.component.scss',
})
export class ConsultationComponent implements OnInit {
  isSummaryOpened = false;
  isMedicationOpened = false;
  isSymptomsOpened = false;
  sessionId: string | null = null;
  session: SessionModel | undefined = undefined;
  constructor(private route: ActivatedRoute, private store: Store<{ session: SessionState }>) {}

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.sessionId = this.route.snapshot.paramMap.get('id');
    if (this.sessionId !== null) {
      this.store.select(selectSessionById(+this.sessionId)).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((session: SessionModel | undefined) => {
          this.session = session;
        });
    }
  }
}
