import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectLanguages } from '../../../../../store/languages/language.selectors';
import { setAppLanguage } from 'libs/common/store/languages/language.actions';
import { LanguageService } from '@hippd/shared/language';
import { TranslateService } from '@tolgee/ngx';

@Component({
  selector: 'hippocraticum-ai-frontend-language-selector',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent {
  public languages$ = this.languageService.languages$;

  constructor(
    private readonly languageService: LanguageService,
    private readonly translate: TranslateService
  ) { }

  public setLanguage(code: string): void {
    this.languageService.selectLanguage(code);
    this.translate.changeLanguage(code);
  }
}
