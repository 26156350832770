<div class="account-menu">
  <img src="./assets/sample-company.png" alt="account logo" />
  <div class="line"></div>
  <button (cdkMenuItemTriggered)="editPasswordSelected.emit()" cdkMenuItem>
    <img src="./assets/password.svg" alt="edit password" />
    <span>{{ 'common.accountMenu.editPassword' | translate }}</span>
  </button>
  <button cdkMenuItem (cdkMenuItemTriggered)="logout()">
    <img src="./assets/logout.svg" alt="logout" />
    <span>{{ 'common.accountMenu.logout' | translate }}</span>
  </button>
</div>
