import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { SessionModel } from '../../../../../models/session-model';
import { addFinishedSession, removeSession, updateSession } from '../../../../../store/sessions/session.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  SessionManagementService
} from '../../../../../../../apps/platform/src/app/services/session-management/session-management.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hippocraticum-ai-frontend-consultation-session',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './consultation-session.component.html',
  styleUrl: './consultation-session.component.scss',
})
export class ConsultationSessionComponent implements OnInit {
  @Input() isOneSectionOpened: boolean = false;
  @Input() areTwoSectionsOpened: boolean = false;
  @Input() session: SessionModel | undefined = undefined;

  private readonly destroyRef = inject(DestroyRef);
  
  isPaused: boolean = false;
  audioStream: MediaStream | null = null;
  timer: number = 0;
  timerDisplay: string = '0:00';
  timerInterval: any;

  constructor(
    private store: Store,
    private router: Router,
    private sessionManagementService: SessionManagementService
  ) {}

  ngOnInit(): void {
    // init consultationData
    if (this.session) {
      this.session = {
        ...this.session,
        consultationData: {
          summary: [],
          symptoms: [],
          medications: [],
        }
      };
    }
  }

  startStreaming(): void {
    if (this.audioStream && !this.isPaused) {
      this.pauseStreaming();
      return;
    }
    if (this.isPaused && this.audioStream) {
      this.isPaused = false;
      this.audioStream.getTracks().forEach((track) => (track.enabled = true));
      this.startTimer();
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.audioStream = stream;
        this.sendAudioStream(stream);
        this.startTimer();
      })
      .catch((error) => {
        console.error('Error accessing audio stream:', error);
      });
  }

  stopStreaming(): void {
    this.isPaused = false;
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    }
    this.stopTimer();

    if (this.session) {
      this.session = { ...this.session, isFinished: true };
      this.sessionManagementService
        .createFinishedSession(this.session).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((session: SessionModel) => {
          this.store.dispatch(addFinishedSession({ session }));
          if (session?.id !== undefined) {
            this.store.dispatch(removeSession({ sessionId: session.id }));
          }
          this.router.navigate(['/dashboard']);
        });
    }
  }

  pauseStreaming(): void {
    this.isPaused = true;
    this.audioStream?.getTracks().forEach((track) => (track.enabled = false));
    this.stopTimer();
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      this.timer++;
      this.updateTimerDisplay();
    }, 1000);
  }

  stopTimer(): void {
    clearInterval(this.timerInterval);
    this.updateTimerDisplay();
  }

  updateTimerDisplay(): void {
    const minutes = Math.floor(this.timer / 60);
    const seconds = this.timer % 60;
    if (minutes < 10) {
      this.timerDisplay = `${minutes}:${this.pad(seconds)}`;
    } else {
      this.timerDisplay = `${this.pad(minutes)}:${this.pad(seconds)}`;
    }
  }

  pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  sendAudioStream(stream: MediaStream): void {
    const audioContext = new AudioContext();
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        if (!this.isPaused) {
          this.sendAudioData(event.data);
        }
      }
    };
    mediaRecorder.start(1000); // Send audio data every second
  }

  sendAudioData(audioData: Blob): void {
    const formData = new FormData();
    formData.append('audio', audioData, 'audio.wav');
    // emulate generation of consultation data
    this.updateConsultationData();
    //this.http.post('/api/audio-stream', formData).subscribe((response: any) => {
    //  this.textData += response.text;
    //});
  }

  updateConsultationData(): void {
    if (this.session?.consultationData) {

      const newConsultationData = {
        ...this.session.consultationData,
        summary: [...(this.session.consultationData.summary || [])],
        symptoms: [...(this.session.consultationData.symptoms || [])],
        medications: [...(this.session.consultationData.medications || [])],
      };

      switch (this.timer) {
        case 3:
          newConsultationData.summary.push(
            'According to the patient, the symptoms have been present for a couple of days (R68.89 - Other general symptoms and signs).' +
            ' The pain comes and goes but is severe when present. The patient has no history of similar symptoms or any recent injuries. ' +
            'The patient also revealed that he has noticed a slight decrease in the volume of his urine.'
          );
          break;
        case 5:
          newConsultationData.symptoms.push({
            description: 'Disorder of kidney',
            duration: 'For 3 days',
          });
          break;
        case 7:
          newConsultationData.summary.push(
            'The patient appears worried and concerned about his symptoms, particularly the presence of blood in his urine.'
          );
          break;
        case 9:
          newConsultationData.symptoms.push({
            description: 'Headache',
            duration: 'For 3 days',
          });
          break;
        case 11:
          newConsultationData.symptoms.push({
            description: 'Headache',
            duration: 'For 3 days',
          });
          break;
        case 15:
          newConsultationData.medications.push({
            description: 'Ibuprofen',
            duration: 'For 3 days',
          });
          break;
        default:
          break;
      }
      this.store.dispatch(updateSession({ session: { ...this.session, consultationData: newConsultationData } }));
    }
  }
}
