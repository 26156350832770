<div class="wrapper" [class.half-height]="isHalfHeight">
  <div class="header">
    <div class="left">
      <mat-icon svgIcon="symptoms"></mat-icon>
      <span>Symptoms</span>
    </div>
    <button
      class="close"
      (click)="close.emit()"
    >
      x
    </button>
  </div>

  <div class="symptoms-summary">
    <hippocraticum-ai-frontend-empty *ngIf="!symptoms?.length"></hippocraticum-ai-frontend-empty>
    <ng-container *ngFor="let symptom of symptoms">
      <hippocraticum-ai-frontend-symptom-item [symptom]="symptom"></hippocraticum-ai-frontend-symptom-item>
    </ng-container>
  </div>
</div>
