import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationMedicationData } from '../../../../../models/session-model';

@Component({
  selector: 'hippocraticum-ai-frontend-medication-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './medication-item.component.html',
  styleUrl: './medication-item.component.scss',
})
export class MedicationItemComponent {
  @Input() medication: ConsultationMedicationData | undefined = undefined;
}
