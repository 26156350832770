import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Language } from './language';
import { BehaviorSubject } from 'rxjs';

export const LANGUAGES = new InjectionToken<Language[]>('LANGUAGES');

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private languagesSubject = new BehaviorSubject<Language[]>(this.languages);

  public languages$ = this.languagesSubject.asObservable();

  constructor(@Inject(LANGUAGES) private languages: Language[]) {
    const savedLanguageCode = localStorage.getItem('language') || 'en';
    this.selectLanguage(savedLanguageCode);
  }

  public setLanguages(languages: Language[]): void {
    this.languages = languages;
  }

  public getLanguages(): Language[] {
    return this.languages;
  }

  public selectLanguage(code: string): void {
    this.languagesSubject.next(
      this.languages.map((language) => {
        if (language.code !== code) {
          return { ...language, selected: false };
        }

        return { ...language, selected: true };
      })
    );
    localStorage.setItem('language', code);
  }
}
