<div class="language-selector" *ngIf="languages$ | async as languages">
  <button *ngFor="let language of languages" (click)="setLanguage(language.code)">
    <div [class.radio-checked]="language.selected" [class.radio]="!language.selected">
      <img src="./assets/checked.svg" alt="checked" />
    </div>
    <img [src]="'./assets/' + language.icon" alt="flag" />
    <span [dir]="language.direction">
      {{ language.title }}
    </span>
  </button>
</div>
