import { createReducer, on } from '@ngrx/store';
import * as LanguageActions from './report-language.actions';
import { LanguageModel } from '../../models/language-model';

export interface LanguageState {
  languages: LanguageModel[];
  error: any;
}

export const REPORT_LANGUAGE_STORE = 'report-languages';

export const initialState: LanguageState = {
  languages: [],
  error: null,
};

export const reportLanguageReducer = createReducer(
  initialState,
  on(LanguageActions.loadReportLanguagesSuccess, (state, { languages }) => ({
    ...state,
    languages,
    error: null,
  })),
  on(LanguageActions.loadReportLanguagesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(LanguageActions.setReportLanguage, (state, { languageId }) => ({
    ...state,
    languages: state.languages.map((language) => {
      if (language.id !== languageId) {
        return { ...language, selected: false };
      }

      return { ...language, selected: true };
    }),
  }))
);
