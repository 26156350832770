<div class="wrapper">
  <div *ngIf="sessionId" class="session-info">
    <button class="session-btn" (click)="goToDashboard()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card header-card">
    <div class="icon-24 logo-24" *ngIf="!sessionId"></div>
    <div *ngIf="sessionId" class="session-info">
      <span class="session-number">Session #{{ sessionId }}</span>
    </div>
    <div class="right-side">
      <ng-content></ng-content>
      <button *ngIf="currentLanguage$ | async as currentLanguage" class="language-btn"
        [cdkMenuTriggerFor]="languageSelector">
        <img [src]="'./assets/' + currentLanguage.selectedIcon" alt="" />
      </button>
    </div>
  </div>
  <div
    class="card header-card account-card"
    *ngIf="(auth.user$ | async) as accountData"
    [cdkMenuTriggerFor]="accountMenu"
  >
    <div class="account-item">
      <img [src]="accountData.picture" alt="account">
    </div>
  </div>
</div>
<ng-template #languageSelector>
  <div cdkMenu>
    <hippocraticum-ai-frontend-language-selector></hippocraticum-ai-frontend-language-selector>
  </div>
</ng-template>

<ng-template #accountMenu>
  <div cdkMenu>
    <hippocraticum-ai-frontend-account-menu
      (editPasswordSelected)="editPasswordSelected.emit()"
      (logoutSelected)="logout()"
    ></hippocraticum-ai-frontend-account-menu>
  </div>
</ng-template>
