<div class="wrapper" [ngClass]="{'with-one-section': isOneSectionOpened, 'with-two-sections': areTwoSectionsOpened}">
  <div class="real-time-consultations">
    <div class="patient-name">{{ session?.name }}</div>
    <div class="timer-display" [class.active]="audioStream && !isPaused">
      {{ timerDisplay }}
      <span class="pause-chip" *ngIf="isPaused">Paused</span>
    </div>
    <div class="summary-description">Quick summary of the patient question</div>
  </div>
  <div class="actions-wrapper">
    <button class="tab-button record-btn" (click)="startStreaming()">
      <mat-icon svgIcon="record" *ngIf="!audioStream || isPaused"></mat-icon>
      <mat-icon svgIcon="pause" *ngIf="!isPaused && audioStream"></mat-icon>
    </button>
    <button *ngIf="audioStream" class="end-session-btn" (click)="stopStreaming()">End Session</button>
  </div>
</div>
