<div class="wrapper" [class.half-height]="isHalfHeight">
  <div class="header">
    <div class="left">
      <mat-icon svgIcon="medications"></mat-icon>
      <span>Medications</span>
    </div>
    <button
      class="close"
      (click)="close.emit()"
    >
      x
    </button>
  </div>

  <div class="medications-summary">
    <hippocraticum-ai-frontend-empty *ngIf="!medications?.length"></hippocraticum-ai-frontend-empty>
    <ng-container *ngFor="let medication of medications">
      <hippocraticum-ai-frontend-medication-item [medication]="medication"></hippocraticum-ai-frontend-medication-item>
    </ng-container>
  </div>
</div>
