import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { EmptyComponent } from '../../common-components/empty/empty.component';
import { ConsultationMedicationData } from '../../../../../models/session-model';
import { MedicationItemComponent } from '../medication-item/medication-item.component';

@Component({
  selector: 'hippocraticum-ai-frontend-consultation-medication',
  standalone: true,
  imports: [CommonModule, MatIcon, EmptyComponent, MedicationItemComponent],
  templateUrl: './consultation-medication.component.html',
  styleUrl: './consultation-medication.component.scss',
})
export class ConsultationMedicationComponent {
  @Input() isHalfHeight: boolean = false;
  @Input() medications: ConsultationMedicationData[] | undefined = [];
  @Output() close = new EventEmitter<void>();
}
