import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LanguageModel } from '../../models';
import { TranslateService } from '@tolgee/ngx';

const languages: LanguageModel[] = [
  {
    id: 1,
    title: 'English',
    icon: 'GB.svg',
    selectedIcon: 'en-icon.svg',
    selected: true,
    direction: 'ltr',
    code: 'en',
    localeId: 'en-US',
  },
  {
    id: 2,
    title: 'Arabic',
    icon: 'AE.svg',
    selectedIcon: 'ar-icon.svg',
    selected: false,
    direction: 'rtl',
    code: 'ar',
    localeId: 'ar-AE',
  },
];

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private readonly availableLanguages = ['en', 'ar'];
  private readonly defaultLanguage = 'en';

  private getSavedLanguage(): string {
    return localStorage.getItem('language') || this.defaultLanguage;
  }

  private setSavedLanguage(language: string) {
    localStorage.setItem('language', language);
  }

  constructor(private readonly translate: TranslateService) {}

  public init() {
    this.translate.changeLanguage(this.getSavedLanguage());
  }

  public setLanguage(language: string) {
    this.translate.changeLanguage(language);
    this.setSavedLanguage(language);
  }

  public getCurrentLanguage(): string {
    return this.translate.language;
  }

  public getAvailableLanguages() {
    return this.availableLanguages;
  }

  public getLanguages() {
    console.log('GET LANGUAGES');
    return of(languages);
  }

  public getReportLanguages() {
    return of(languages);
  }
}
