import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { provideStore, Store } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY,
  rtMedicalConvoAnalyserReducer,
} from '@hippocraticum-ai-frontend/consultation-demo';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  SESSION_STORE,
  sessionReducer,
} from '../../../../libs/common/store/sessions/session.reducer';
import { SessionEffects } from '../../../../libs/common/store/sessions/session.effects';
import {
  PATIENT_STORE,
  patientReducer,
} from '../../../../libs/common/store/patients/patient.reducer';
import { PatientEffects } from '../../../../libs/common/store/patients/patient.effects';
import {
  LANGUAGE_STORE,
  languageReducer,
} from 'libs/common/store/languages/language.reducer';
import { Language, LANGUAGES } from '@hippd/shared/language';
import {
  REPORT_LANGUAGE_STORE,
  reportLanguageReducer,
} from 'libs/common/store/report-languages/report-language.reducer';

import { LanguageEffects } from 'libs/common/store/languages/language.effects';
import { ReportLanguageEffects } from 'libs/common/store/report-languages/report-language.effects';

import {
  loadLanguages,
  setAppLanguage,
} from 'libs/common/store/languages/language.actions';
import { loadReportLanguages } from 'libs/common/store/report-languages/report-language.actions';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideHttpClient } from '@angular/common/http';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { AuthModule } from '@auth0/auth0-angular';

import {
  DevTools,
  Tolgee,
  TOLGEE_INSTANCE,
  NgxTolgeeModule,
  LanguageDetector,
} from '@tolgee/ngx';

import { FormatIcu } from '@tolgee/format-icu';

const applicationLanguages: Language[] = [
  {
    id: 1,
    title: 'English',
    icon: 'GB.svg',
    selectedIcon: 'en-icon.svg',
    selected: true,
    direction: 'ltr',
    code: 'en',
    localeId: 'en-US',
  },
  {
    id: 2,
    title: 'Arabic',
    icon: 'AE.svg',
    selectedIcon: 'ar-icon.svg',
    selected: false,
    direction: 'rtl',
    code: 'ar',
    localeId: 'ar-AE',
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      AuthModule.forRoot({
        domain: environment.auth.domain,
        clientId: environment.auth.clientId,
        authorizationParams: {
          redirect_uri: environment.auth.authorizationParams.redirect_uri,
          organization: environment.auth.authorizationParams.organization,
        },
      })
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideAngularSvgIcon(),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideStore(
      {
        [RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY]: rtMedicalConvoAnalyserReducer,
        [SESSION_STORE]: sessionReducer,
        [PATIENT_STORE]: patientReducer,
        [REPORT_LANGUAGE_STORE]: reportLanguageReducer,
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    provideHttpClient(),
    provideEffects([
      SessionEffects,
      PatientEffects,
      LanguageEffects,
      ReportLanguageEffects,
    ]),
    provideStoreDevtools({ maxAge: 40, trace: true }),
    provideRouterStore(),
    provideAnimations(),
    provideNativeDateAdapter(),
    provideEnvironmentNgxMask(),
    provideHttpClient(),
    provideEnvironmentNgxMask(),
    { provide: LANGUAGES, useValue: applicationLanguages },
    NgxTolgeeModule,
    {
      provide: TOLGEE_INSTANCE,
      useFactory: () => {
        const currenLanguage = localStorage.getItem('language') || 'en';

        const tolgee = Tolgee()
          .use(LanguageDetector())
          .use(DevTools())
          .use(FormatIcu())
          .init({
            fallbackLanguage: 'en',
            defaultLanguage: 'en',
            language: 'en',
            availableLanguages: ['en, ar'],
            staticData: {
              en: () => import('../../assets/i18n/en.json') as any,
              ar: () => import('../../assets/i18n/ar.json') as any,
            },
            // apiUrl: environment.tolgee.tolgeeApiUrl,
            // apiKey: environment.tolgee.tolgeeApiKey,
          });

        tolgee.run().then(() => {
          tolgee.changeLanguage(currenLanguage);
        });

        return tolgee;
      },
    },
  ],
};
