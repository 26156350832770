import {DestroyRef, inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
    gotMedicalNote, gotFinalReport, gotWordGroup, gotTranscription
} from '../+state/-rt-medical-convo-analyser.actions';
import {environment} from '../../../../../../environments/environment';
import {Observable} from 'rxjs';
import {
    CurrentView,
    Sentence,
    SentenceState,
    Transcription,
    TranscriptionV2
} from '../+state/-rt-medical-convo-analyser.types';
import {selectCurrentView} from '../+state/-rt-medical-convo-analyser.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
    providedIn: 'root'
})
export class HippocraticumBackendService {
    private readonly destroyRef = inject(DestroyRef);
    private audioContext!: AudioContext;
    private audioWorkletNode!: AudioWorkletNode;
    private websocket: WebSocket | undefined;
    private $currentView: Observable<CurrentView>

    constructor(private store: Store) {
        this.$currentView = this.store.select(selectCurrentView)

        this.$currentView.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(currentView => {
            if (currentView === CurrentView.SessionInit) {
                this.initializeAudioContext();
            }
        });
    }

    private initializeAudioContext() {
        this.audioContext = new AudioContext();

        this.audioContext.audioWorklet.addModule('assets/audio-processor.js').then(() => {
            this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor', {
                processorOptions: {sampleRate: this.audioContext.sampleRate}
            });

            this.audioWorkletNode.port.onmessage = (event) => {
                const int16AudioData = new Int16Array(event.data);
                if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                    this.websocket.send(int16AudioData.buffer);
                }
            };
        }).catch(error => {
            console.error('Error loading Audio Processor:', error);
        });
    }

    private messageConverter(action: string) {
        const jsonString = JSON.stringify({action: action});
        const encoder = new TextEncoder();
        const byteArray = encoder.encode(jsonString);
        const base64String = btoa(String.fromCharCode(...byteArray));
        const base64ByteArray = new Uint8Array(base64String.length).map((_, i) => base64String.charCodeAt(i));
        this.websocket?.send(base64ByteArray.buffer);
    }

    private initializeWebSocket() {
        this.websocket = new WebSocket(environment.baseWsUrl);

        this.websocket.onopen = () => {
            console.debug('WebSocket connection to ' + environment.baseWsUrl + ' established.');
            this.messageConverter("start");
        }

        this.websocket.onclose = () => {
            console.debug('WebSocket connection closed.');
        }

        this.websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(data)

            if ("wordGroups" in data) {
                console.debug('displaying word groups:');
                this.store.dispatch(gotWordGroup({wordGroups: data.wordGroups}))
            }

            if ("transcription" in data) {
                console.debug('displaying transcription:');
                const received_transcriptions: Sentence[] = data.transcription.sentences
                const converted_transcriptions: Transcription[] = []

                received_transcriptions.forEach((transcription) => {

                    const is_completed = transcription.state != SentenceState.PROCESSED

                    converted_transcriptions.push({
                        id: transcription.id.toString(),
                        text: transcription.text,
                        speaker: transcription.speaker,
                        is_complete: is_completed
                    })
                })

                this.store.dispatch(gotTranscription({transcription: converted_transcriptions}))
            }

            if ("realTimeMedicalNote" in data) {
                console.debug('displaying medical note:');
                this.store.dispatch(gotMedicalNote({medicalNote: data.realTimeMedicalNote}))
            }

            if ("final_report" in data) {
                console.debug('displaying final report:');
                this.store.dispatch(gotFinalReport({finalReport: data.final_report}))
            }
        }
    }

    start(audioStream
              :
              MediaStream
    ) {
        this.audioContext.resume().then(() => {
            this.initializeWebSocket();
            const source = this.audioContext.createMediaStreamSource(audioStream);
            source.connect(this.audioWorkletNode);
            this.audioWorkletNode.connect(this.audioContext.destination);
        });
    }

    pause() {
        this.audioContext.suspend().then(() => {
            console.debug('AudioContext suspended.');
        });
    }

    resume() {
        this.audioContext.resume().then(() => {
            console.debug('AudioContext resumed.');
        });
    }

    stop() {
        return this.audioContext.suspend().then(() => {
            console.debug('AudioContext suspended.');
            this.messageConverter("end");
            return this.audioContext.currentTime;
        });
    }

    close() {
        if (this.audioContext && this.audioContext.state !== 'closed') {
            this.audioContext.close().then(() => {
                console.debug('AudioContext closed.');

                if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                    this.messageConverter("close");
                    this.websocket.close();
                }
            }).catch(err => {
                console.error('Error while closing AudioContext:', err);
            });
        } else {
            console.warn('AudioContext is already closed or not available.');

            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                this.messageConverter("close");
                this.websocket.close();
            }
        }
    }
}

