import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomIconService } from './services/custom-icon/custom-icon.service';

@Component({
  selector: 'hippocraticum-ai-frontend-root',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private iconService: CustomIconService) {
    this.iconService.registerAllIcons();
  }
  ngOnInit() {
    this.adjustViewportHeight();
    window.addEventListener('resize', this.adjustViewportHeight);
  }

  adjustViewportHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
}
