import { createAction, props } from '@ngrx/store';
import { SessionModel } from '../../models/session-model';

export const addSession = createAction(
  '[Session] Add Session',
  props<{ session: SessionModel }>()
);
export const updateSession = createAction(
  '[Session] Update Session',
  props<{ session: SessionModel }>()
);
export const removeSession = createAction(
  '[Session] Remove Session',
  props<{ sessionId: number }>()
);
export const removeSessionSuccess = createAction(
  '[Session] Remove Session Success',
  props<{ sessionId: number }>()
);

export const removeSessionFailure = createAction(
  '[Session] Remove Session Failure',
  props<{ error: any }>()
);

export const updateSessionSuccess = createAction(
  '[Session] Update Session Success',
  props<{ session: SessionModel }>()
);

export const updateSessionFailure = createAction(
  '[Session] Update Session Failure',
  props<{ error: any }>()
);
export const loadAllSessions = createAction('[Session] Load All Sessions');
export const loadAllSessionsSuccess = createAction(
  '[Session] Load All Sessions Success',
  props<{ sessions: SessionModel[] }>()
);

export const addFinishedSession = createAction(
  '[Session] Add Finished Session',
  props<{ session: SessionModel }>()
);
export const loadAllFinishedSessions = createAction(
  '[Session] Load All Finished Sessions'
);
export const loadAllFinishedSessionsSuccess = createAction(
  '[Session] Load All Finished Sessions Success',
  props<{ sessions: SessionModel[] }>()
);
