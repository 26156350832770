import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  loadAllSessions,
  loadAllSessionsSuccess,
  loadAllFinishedSessions,
  loadAllFinishedSessionsSuccess,
  updateSession,
  updateSessionFailure,
  updateSessionSuccess, removeSession, removeSessionSuccess, removeSessionFailure
} from './session.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SessionManagementService } from '../../../../apps/platform/src/app/services/session-management/session-management.service';
import { of } from 'rxjs';

@Injectable()
export class SessionEffects {
  loadAllSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllSessions),
      mergeMap(() =>
        this.sessionService
          .getAllSessions()
          .pipe(map((sessions) => loadAllSessionsSuccess({ sessions })))
      )
    )
  );

  loadAllFinishedSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllFinishedSessions),
      mergeMap(() =>
        this.sessionService.getAllFinishedSessions().pipe(
          map((sessions) => {
            return loadAllFinishedSessionsSuccess({ sessions });
          })
        )
      )
    )
  );

  updateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSession),
      mergeMap((action) =>
        this.sessionService.updateSession(action.session).pipe(
          map((session) => updateSessionSuccess({ session })),
          catchError((error) => of(updateSessionFailure({ error })))
        )
      )
    )
  );

  removeSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSession),
      mergeMap(action =>
        this.sessionService.removeSession(action.sessionId).pipe(
          map(sessionId => removeSessionSuccess({ sessionId })),
          catchError(error => of(removeSessionFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sessionService: SessionManagementService,
    private readonly store: Store
  ) { }
}
