export class Language {
  constructor(
    public id: number,
    public title: string,
    public icon: string,
    public selectedIcon: string,
    public selected: boolean,
    public direction: 'rtl' | 'ltr',
    public code: string,
    public localeId: string
  ) {}
}
