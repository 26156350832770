import { createAction, props } from '@ngrx/store';
import { LanguageModel } from '../../models/language-model';

export const loadReportLanguages = createAction(
  '[Report Language] Load Report Languages'
);

export const loadReportLanguagesSuccess = createAction(
  '[Report Language] Load Report Languages Success',
  props<{ languages: LanguageModel[] }>()
);

export const loadReportLanguagesFailure = createAction(
  '[Report Language] Load Report Languages Failure',
  props<{ error: any }>()
);

export const setReportLanguage = createAction(
  '[Report Language] Set Report Language',
  props<{ languageId: number }>()
);
