import { createReducer, on } from '@ngrx/store';
import { SessionModel } from '../../models/session-model';
import {
  addSession,
  loadAllSessionsSuccess,
  addFinishedSession,
  loadAllFinishedSessionsSuccess,
  updateSessionSuccess,
  updateSessionFailure, removeSessionSuccess, removeSessionFailure
} from './session.actions';

export const SESSION_STORE = 'session';

export interface SessionState {
  sessions: SessionModel[];
  finishedSessions: SessionModel[];
}

export const initialState: SessionState = {
  sessions: [],
  finishedSessions: [],
};

export const sessionReducer = createReducer(
  initialState,
  on(addSession, (state, { session }) => ({
    ...state,
    sessions: [...state.sessions, session],
  })),
  on(loadAllSessionsSuccess, (state, { sessions }) => ({
    ...state,
    sessions,
  })),
  on(addFinishedSession, (state, { session }) => ({
    ...state,
    finishedSessions: [...state.finishedSessions, session],
  })),
  on(loadAllFinishedSessionsSuccess, (state, { sessions }) => ({
    ...state,
    finishedSessions: sessions,
  })),
  on(updateSessionSuccess, (state, { session }) => ({
    ...state,
    sessions: state.sessions.map((s) => (s.id === session.id ? session : s)),
    error: null,
  })),
  on(updateSessionFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(removeSessionSuccess, (state, { sessionId }) => ({
    ...state,
    sessions: state.sessions.filter((session) => session.id !== sessionId),
  })),
  on(removeSessionFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
