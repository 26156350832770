import { createReducer, on } from '@ngrx/store';
import * as PatientActions from './patient.actions';
import { PatientModel } from '../../models/patient-model';

export interface PatientState {
  patients: PatientModel[];
  error: any;
}

export const PATIENT_STORE = 'patient';

export const initialState: PatientState = {
  patients: [],
  error: null,
};

export const patientReducer = createReducer(
  initialState,
  on(PatientActions.addPatientSuccess, (state, { patient }) => ({
    ...state,
    patients: [...state.patients, patient],
    error: null,
  })),
  on(PatientActions.addPatientFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
