import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LanguageActions from './language.actions';
import { LanguagesService } from '../../services/languages/languages.service';
import { catchError, EMPTY, map, mergeMap, of } from 'rxjs';

@Injectable()
export class LanguageEffects {
  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguages),
      mergeMap(() =>
        this.languagesService.getLanguages().pipe(
          map((languages) =>
            LanguageActions.loadLanguagesSuccess({ languages })
          ),
          catchError((error) =>
            of(LanguageActions.loadLanguagesFailure({ error }))
          )
        )
      )
    )
  );

  setAppLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageActions.setAppLanguage),
        map(({ code }) => {
          this.languagesService.setLanguage(code);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly languagesService: LanguagesService
  ) {}
}
