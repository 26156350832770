<div class="wrapper">
  <div class="header">
    <div class="left">
      <mat-icon svgIcon="summary"></mat-icon>
      <span>Summary</span>
    </div>
    <button
      class="close"
      (click)="close.emit()"
    >
      x
    </button>
  </div>

  <div class="sessions-summary">
    <hippocraticum-ai-frontend-empty *ngIf="!summary?.length"></hippocraticum-ai-frontend-empty>
    <ng-container *ngFor="let text of summary">
      <div class="text">{{ text }}</div>
    </ng-container>
  </div>
</div>
