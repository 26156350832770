import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { AccountMenuComponent } from '../account-menu/account-menu.component';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { selectLanguages } from 'libs/common/store/languages/language.selectors';
import { map } from 'rxjs';
import { LanguageService } from '@hippd/shared/language';

@Component({
  selector: 'hippocraticum-ai-frontend-header',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    LanguageSelectorComponent,
    AccountMenuComponent,
    CdkMenuModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() sessionId: string | null = null;
  @Output() editPasswordSelected = new EventEmitter();

  currentLanguage$ = this.languageService.languages$.pipe(
    map((languages) => languages.find((lang) => lang.selected))
  );

  constructor(
    private router: Router,
    public auth: AuthService,
    private languageService: LanguageService
  ) { }

  logout(): void {
    this.auth.logout();
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
