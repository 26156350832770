import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationSymptomData } from '../../../../../models/session-model';

@Component({
  selector: 'hippocraticum-ai-frontend-symptom-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './symptom-item.component.html',
  styleUrl: './symptom-item.component.scss',
})
export class SymptomItemComponent {
  @Input() symptom: ConsultationSymptomData | undefined = undefined;
}
