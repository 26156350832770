import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { EmptyComponent } from '../../common-components/empty/empty.component';

@Component({
  selector: 'hippocraticum-ai-frontend-consultation-summary',
  standalone: true,
  imports: [CommonModule, MatIcon, EmptyComponent],
  templateUrl: './consultation-summary.component.html',
  styleUrl: './consultation-summary.component.scss',
})
export class ConsultationSummaryComponent {
  @Input() summary: string[] | undefined = [];
  @Output() close = new EventEmitter<void>();
}
