import { createAction, props } from '@ngrx/store';
import { LanguageModel } from '../../models/language-model';

export const loadLanguages = createAction('[Language] Load Languages');

export const loadLanguagesSuccess = createAction(
  '[Language] Load Languages Success',
  props<{ languages: LanguageModel[] }>()
);

export const loadLanguagesFailure = createAction(
  '[Language] Load Languages Failure',
  props<{ error: any }>()
);

export const setAppLanguage = createAction(
  '[Language] Set App Language',
  props<{ code: string }>()
);
