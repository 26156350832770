<div class="wrapper">
  <hippocraticum-ai-frontend-header [sessionId]="sessionId">
    <button class="tab-button icon-24" (click)="isSummaryOpened = !isSummaryOpened" [class.opened]="isSummaryOpened">
      <mat-icon svgIcon="summary"></mat-icon>
    </button>
    <button class="tab-button icon-24" (click)="isSymptomsOpened = !isSymptomsOpened" [class.opened]="isSymptomsOpened">
      <mat-icon svgIcon="symptoms"></mat-icon>
    </button>
    <button class="tab-button icon-24" (click)="isMedicationOpened = !isMedicationOpened" [class.opened]="isMedicationOpened">
      <mat-icon svgIcon="medications"></mat-icon>
    </button>
  </hippocraticum-ai-frontend-header>
  <div class="content">

    <hippocraticum-ai-frontend-consultation-session
      [session]="session"
      [isOneSectionOpened]="isSummaryOpened || isSymptomsOpened || isMedicationOpened"
      [areTwoSectionsOpened]="isSummaryOpened && (isSymptomsOpened || isMedicationOpened)">
    </hippocraticum-ai-frontend-consultation-session>

    <hippocraticum-ai-frontend-consultation-summary
      *ngIf="isSummaryOpened"
      [summary]="session?.consultationData?.summary"
      (close)="isSummaryOpened = false">
    </hippocraticum-ai-frontend-consultation-summary>

    <div class="second-sections" *ngIf="isSymptomsOpened || isMedicationOpened">
      <hippocraticum-ai-frontend-consultation-symptoms
        *ngIf="isSymptomsOpened"
        [isHalfHeight]="isMedicationOpened"
        [symptoms]="session?.consultationData?.symptoms"
        (close)="isSymptomsOpened = false">
      </hippocraticum-ai-frontend-consultation-symptoms>

      <hippocraticum-ai-frontend-consultation-medication
        *ngIf="isMedicationOpened"
        [isHalfHeight]="isSymptomsOpened"
        [medications]="session?.consultationData?.medications"
        (close)="isMedicationOpened = false">
      </hippocraticum-ai-frontend-consultation-medication>
    </div>

  </div>
</div>
